.swal2-toast {
  background: var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary-contrast) !important;

  & .swal2-error {
    border-color: var(--ion-color-danger) !important;

    & .swal2-x-mark > * {
      background-color: var(--ion-color-danger) !important;
    }
  }
}

.btn-danger {
  background: var(--ion-color-danger) !important;
  color: var(--ion-color-danger-contrast) !important;
}

.swal2-cancel {
  background: var(--ion-color-medium) !important;
  color: var(--ion-color-medium-contrast) !important;
}