.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;

  img {
    width: 80px;
    height: auto;
    animation: blink 1s ease-in-out infinite;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}